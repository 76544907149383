import React from 'react'

import { Global } from './styles'

type Props = {
  id?: string
  backgroundColor?: 'white' | 'blue'
  backgroundImageUrl?: string
}

const Page: React.FC<React.PropsWithChildren<Props>> = ({
  id,
  backgroundColor = 'white',
  backgroundImageUrl,
  children,
}) => {
  return (
    <React.Fragment key={id}>
      <Global backgroundColor={backgroundColor} backgroundImageUrl={backgroundImageUrl} />
      {children}
    </React.Fragment>
  )
}

export default Page
