import React from 'react'
import { Grid, PageSection as Section, VerticalSpacer, Button } from '@superhi/design'

import LibraryGuideCard from '../../LibraryGuideCard'

import { API } from 'src/api'
import { SUPERLIBRARY_URL } from 'src/config'

const VS_1_AMOUNT = { small: 24, medium: 24, large: 32 }
const GRID_1_SLIDER = { small: true, medium: true }
const GUIDES_PATH = `${SUPERLIBRARY_URL}/guides`
const CELL_SPAN_1 = { small: 3, medium: 3, large: 4 }

type Props = API.SuperLibraryGuidesComponent &
  Pick<
    React.ComponentProps<typeof Section>,
    | 'collapseTopPadding'
    | 'collapseBottomPadding'
    | 'decorationName'
    | 'decorationPosition'
    | 'noTopPadding'
  > & {
    title?: string
    subtitle?: string
    imagesLazy?: boolean
    headingLevel?: number
  }

const SuperLibraryGuides: React.FC<React.PropsWithChildren<Props>> = ({
  title = 'SuperHi On...',
  subtitle = 'View our growing collection of guides for creatives, featuring practical insights and advice on careers, making, and working.',
  decorationName,
  decorationPosition,
  collapseBottomPadding,
  collapseTopPadding,
  imagesLazy = false,
  noTopPadding,
  nodes,
  headingLevel = 2,
}) => {
  const safeGuides = nodes || []

  return (
    <Section
      title={title}
      subtitle={subtitle}
      footer={
        <Button href={GUIDES_PATH} isExternal>
          View all guides
        </Button>
      }
      decorationName={decorationName}
      decorationPosition={decorationPosition}
      collapseBottomPadding={collapseBottomPadding}
      collapseTopPadding={collapseTopPadding}
      noTopPadding={noTopPadding}
      headingLevel={headingLevel}
    >
      <VerticalSpacer amount={VS_1_AMOUNT}>
        <Grid slider={GRID_1_SLIDER}>
          {safeGuides.map((guide) => (
            <Grid.Cell key={guide.slug} span={CELL_SPAN_1}>
              <LibraryGuideCard {...guide} imageLazy={imagesLazy} />
            </Grid.Cell>
          ))}
        </Grid>
      </VerticalSpacer>
    </Section>
  )
}

export default SuperLibraryGuides
