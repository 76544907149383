import { gql } from '@apollo/client'

export default gql`
  fragment CourseCardComponent on ProductCourse {
    id
    slug
    title
    image {
      url
      blurhash
    }
    isBeta
    difficultyLevel
    releaseDate
    shortDescription
    lowestPrice {
      value
      currencyCode
    }
  }
`
