import React from 'react'
import { PageSection as Section, Button, Grid, HelpCenterArticleList } from '@superhi/design'

import { buildPath } from 'src/routing'
import { API } from 'src/api'

const HELP_CENTER_PATH = buildPath('help-center-index')
const CELL_SPAN_1: React.ComponentProps<typeof Grid.Cell>['span'] = {
  small: 'full',
  medium: [2, 7],
  large: [3, 10],
}

type Props = API.HelpCenterArticlesComponent &
  Pick<
    React.ComponentProps<typeof Section>,
    'collapseTopPadding' | 'collapseBottomPadding' | 'decorationName' | 'decorationPosition'
  > & {
    id?: string
    title?: string
    subtitle?: string
    footerButtonText?: string
  }

const HelpCenterArticles: React.FC<React.PropsWithChildren<Props>> = ({
  id,
  title = 'Frequently asked questions',
  subtitle,
  collapseTopPadding,
  collapseBottomPadding,
  decorationName,
  decorationPosition,
  footerButtonText = 'View more',
  nodes,
}) => {
  const articles = nodes || []

  return (
    <Section
      id={id}
      title={title}
      subtitle={subtitle}
      footer={<Button href={HELP_CENTER_PATH}>{footerButtonText}</Button>}
      collapseTopPadding={collapseTopPadding}
      collapseBottomPadding={collapseBottomPadding}
      decorationName={decorationName}
      decorationPosition={decorationPosition}
      headingLevel={2}
    >
      <Grid>
        <Grid.Cell span={CELL_SPAN_1}>
          {articles.length > 0 ? (
            <HelpCenterArticleList articles={articles} headingLevel={3} />
          ) : null}
        </Grid.Cell>
      </Grid>
    </Section>
  )
}

export default HelpCenterArticles
