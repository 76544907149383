import { gql } from '@apollo/client'

import { FRAGMENT as COURSE_CARD_FRAGMENT } from '../../CourseCard'

export default gql`
  fragment TopCoursesComponent on ProductCourseList {
    nodes {
      ...CourseCardComponent
    }
  }

  ${COURSE_CARD_FRAGMENT}
`
