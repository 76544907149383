import React from 'react'
import { getImgProps, LibraryGuideCard as LibraryGuideCardComponent } from '@superhi/design'

import { API } from 'src/api'
import { SUPERLIBRARY_URL } from 'src/config'

type ComponentProps = Omit<
  React.ComponentProps<typeof LibraryGuideCardComponent>,
  'href' | 'image' | 'link'
>

type Props = ComponentProps &
  API.LibraryGuideCardComponent & {
    imageLazy?: boolean
  }

const LibraryGuideCard: React.FC<React.PropsWithChildren<Props>> = ({
  slug,
  image,
  imageLazy = false,
  ...props
}) => {
  const imageProps = React.useMemo(
    () => ({
      ...getImgProps(
        image.url,
        {
          small: 100,
          medium: 50,
          large: 33,
          xlarge: 33,
        },
        {
          fm: 'png8',
        },
      ),
      lazy: imageLazy,
      blurHash: image.blurhash,
    }),
    [image, imageLazy],
  )

  const href = `${SUPERLIBRARY_URL}/guides/${slug}`

  return (
    <LibraryGuideCardComponent {...props} link={{ href, isExternal: true }} image={imageProps} />
  )
}

export { default as FRAGMENT } from './fragments'
export default LibraryGuideCard
