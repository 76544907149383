import { createGlobalStyle } from 'styled-components'
import { theme } from '@superhi/design'

type GlobalProps = {
  backgroundColor: 'white' | 'blue'
  backgroundImageUrl?: string
}

export const Global = createGlobalStyle<GlobalProps>`
  body {
    background-color: ${({ backgroundColor }) =>
      backgroundColor === 'white' ? theme.colors.WHITE : theme.colors.PALE_BLUE_40};
    background-image: ${({ backgroundImageUrl }) =>
      backgroundImageUrl ? `url(${backgroundImageUrl})` : ''};
    background-size: cover;
    background-attachment: fixed;
  }
`
