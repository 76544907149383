import React from 'react'
import { ProductCoursePreview as CourseCardComponent, Price, getImgProps } from '@superhi/design'
import { useQuery } from '@apollo/client'

import { useIsPreRelease } from '../../pages/Courses/utils'

import QUERY from './queries'

import { buildPath } from 'src/routing'
import { API, useSession } from 'src/api'

export const formatDate = (dateString: string) => {
  const currentYear = new Date().getFullYear()
  const [year, month, date] = dateString.split('-')
  const thedate = new Date(
    year as unknown as number,
    parseInt(month) - 1,
    date as unknown as number,
  )

  const formatted = new Intl.DateTimeFormat('en-GB', {
    dateStyle: 'long',
  }).format(thedate)

  if (currentYear.toString() === year) {
    return formatted.replace(year, '').trim()
  }

  return formatted
}

type ComponentProps = Omit<
  React.ComponentProps<typeof CourseCardComponent>,
  'href' | 'description' | 'difficultyLevel' | 'price' | 'image'
>

type Props = ComponentProps &
  API.CourseCardComponent & {
    imageLazy?: boolean
  }

const CourseCard: React.FC<React.PropsWithChildren<Props>> = ({
  id,
  slug,
  lowestPrice,
  shortDescription,
  image,
  imageLazy = false,
  releaseDate,
  ...props
}) => {
  const [, , session] = useSession()
  const { data } = useQuery<API.CourseCardComponent_price, API.CourseCardComponent_priceVariables>(
    QUERY,
    {
      variables: {
        id,
      },
      ssr: false,
    },
  )
  const imageProps = React.useMemo(
    () => ({
      ...getImgProps(
        image.url,
        {
          small: 100,
          medium: 50,
          large: 33,
          xlarge: 33,
        },
        {
          fm: 'png8',
        },
      ),
      lazy: imageLazy,
    }),
    [image.url, imageLazy],
  )
  const href = React.useMemo(() => buildPath('courses-view', { params: { slug } }), [slug])

  const isPreRelease = useIsPreRelease(releaseDate)

  const formattedReleaseDate = React.useMemo(
    () => (releaseDate ? formatDate(releaseDate) : undefined),
    [releaseDate],
  )

  const price = data?.productCourse?.lowestPrice || lowestPrice

  return (
    <CourseCardComponent
      {...props}
      href={href}
      price={price.value === 0 ? 'FREE' : Price.format(price.value, price.currencyCode)}
      description={shortDescription}
      message={
        isPreRelease
          ? `${
              !session.hasActiveMembership ? `Pre-order now. ` : ''
            }Released on ${formattedReleaseDate}.`
          : undefined
      }
      image={{ ...imageProps, blurHash: image.blurhash }}
    />
  )
}

export { default as FRAGMENT } from './fragments'
export default CourseCard
