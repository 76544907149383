import { gql } from '@apollo/client'

export default gql`
  query CourseCardComponent_price($id: ID!) {
    productCourse(id: $id) {
      id
      lowestPrice {
        value
        currencyCode
      }
    }
  }
`
