import { gql } from '@apollo/client'

export default gql`
  fragment HelpCenterArticlesComponent on HelpCenterArticleList {
    nodes {
      id
      title
      body
    }
  }
`
