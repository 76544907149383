import { gql } from '@apollo/client'

export default gql`
  fragment LibraryGuideCardComponent on LibraryGuide {
    slug
    title
    image {
      url
      blurhash
    }
  }
`
