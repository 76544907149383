import { gql } from '@apollo/client'

import { FRAGMENT as LIBRARY_GUIDE_CARD_FRAGMENT } from '../../LibraryGuideCard'

export default gql`
  fragment SuperLibraryGuidesComponent on LibraryGuideList {
    nodes {
      ...LibraryGuideCardComponent
    }
  }

  ${LIBRARY_GUIDE_CARD_FRAGMENT}
`
