import React from 'react'
import { PageSection as Section, Button, Grid } from '@superhi/design'

import CourseCard from '../../CourseCard'

import { buildPath } from 'src/routing'
import { API } from 'src/api'

const COURSES_PATH = buildPath('courses-index')
const TITLE = 'All-time favorites'

const EMPTY_COURSES: any[] = []

type Props = API.TopCoursesComponent &
  Pick<
    React.ComponentProps<typeof Section>,
    'collapseBottomPadding' | 'noBottomPadding' | 'collapseTopPadding' | 'noTopPadding'
  > & {
    title?: string
    subtitle?: string
    perPage?: number
    imagesLazy?: boolean
  }

const TopCourses: React.FC<React.PropsWithChildren<Props>> = ({
  title = TITLE,
  subtitle,
  imagesLazy = false,
  collapseBottomPadding,
  collapseTopPadding,
  noBottomPadding,
  noTopPadding,
  nodes,
}) => {
  const safeCourses = nodes || EMPTY_COURSES

  return (
    <Section
      title={title}
      subtitle={subtitle}
      footer={<Button href={COURSES_PATH}>View all courses</Button>}
      collapseBottomPadding={collapseBottomPadding}
      collapseTopPadding={collapseTopPadding}
      noBottomPadding={noBottomPadding}
      noTopPadding={noTopPadding}
      headingLevel={2}
    >
      <Grid alignItems="stretch" slider={{ small: true, medium: safeCourses.length < 6 }}>
        {safeCourses.map((course, i) => (
          <Grid.Cell
            key={i}
            span={{
              small: 3,
              medium: safeCourses.length < 6 ? 3 : 4,
              large: 4,
            }}
          >
            <CourseCard {...course} imageLazy={imagesLazy} headingLevel={3} />
          </Grid.Cell>
        ))}
      </Grid>
    </Section>
  )
}

export default TopCourses
